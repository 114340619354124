<template>
  <div id="page-news" class="container-fluid">
    <div class="page-header mb-1 d-flex justify-content-between align-items-center">
      <strong>ประกาศด่วน</strong>
      <button class="btn btn-link btn-sm p-0" @click="addNews">เพิ่มประกาศ</button>
    </div>

    <ListNews type="express" :reload="toggleReload" @loaded="toggleReload=false" />
    <NewsForm :data="data" :isShow="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import ListNews from './components/ListNews'
import NewsForm from './components/NewsForm'

export default {
  name: 'ExpressNews',
  components: {
    ListNews,
    NewsForm
  },
  data() {
    return {
      toggleReload: false,
      isShowModal: false,
      data: null
    }
  },
  methods: {
    addNews() {
      this.data = {
        _id: null,
        type: 'express',
        subject: '',
        htmlDetails: '',
        receivers: ['Company', 'Agent', 'Member'],
        popupDisplay: 'first'
      }

      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.toggleReload = true
      }
    },
  }
}
</script>
